@charset 'utf-8';

// Animation

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}
// USAGE
// @include keyframes(slide-down) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }

// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('slide-down 5s 3');
// }

@mixin transition($time, $property: all, $easing: ease-in) {
    transition: $property $time $easing;
    -webkit-transition: $property $time $easing; 
       -moz-transition: $property $time $easing; 
         -o-transition: $property $time $easing; 
            transition: $property $time $easing; 

    -webkit-transition-timing-function: $easing; 
       -moz-transition-timing-function: $easing; 
         -o-transition-timing-function: $easing; 
            transition-timing-function: $easing; 
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $left $top $blur $color;
    -moz-box-shadow:inset $left $top $blur $color;
    box-shadow:inset $left $top $blur $color;
  } @else {
    -webkit-box-shadow: $left $top $blur $color;
    -moz-box-shadow: $left $top $blur $color;
    box-shadow: $left $top $blur $color;
  }
}


@charset 'utf-8';
// font-family:'FranklinGothicW01-Cond';
// font-family:'FranklinGothicW01-No.2R';
// font-family:'FranklinGothicW01-Extra_692515';
// font-family:'Frutiger LT W01_45 Ligh1475730';
// font-family:'FrutigerNeue1450W01-Bol1196308';
// font-family:'FrutigerNeue1450W01-Boo1196293';
// font-family:'FrutigerNeue1450W01-Bol1196311';

// horizontal spacer
  $spacer-heights: (
    tiny: 1rem,
    small: 1rem,
    medium: 2rem,
    large: 3rem,
    xlarge: 4rem,
  );
  @each $size, $spacer-height in $spacer-heights {
    @include breakpoint($size) {
        .spacer {
          height: rem-calc($spacer-height);
          &.quarter {
            height: rem-calc($spacer-height / 4);
          }
          &.half {
            height: rem-calc($spacer-height / 2);
          }
          &.double {
            height: rem-calc($spacer-height * 2);
          }
          &.triple {
            height: rem-calc($spacer-height * 3);
          }
        }
    }
  }

:focus{
  outline: none;
}
.flat {
  margin-bottom: 0;
}
.top-bar-title {
  font-family: $body-font-family-bold;
  .header-logo {
    height: 2rem;
    margin-right: 0.5rem;
  }  
}
.expand {
  width: 100%;
}
.text-center {
  text-align: center;
}
.button {
  font-family: $body-font-family-bold;
}
.menu {
  li {
    a {
      color: $body-font-color;
      padding: 0.5rem 1rem;
      @include transition(0.2s, color);
      &:hover {
        color: $primary-color;
      }
    }
  }
}
.off-canvas-wrapper {
  background: $primary-color;
  .off-canvas {
    .menu {
      li {
        a {
          color: lighten($primary-color, 30);
          font-family: $body-font-family-bold;
          @include transition(0.2s, color);
          &:hover {
            color: lighten($primary-color, 50);
          }
        }
      }
    }
  }
  .off-canvas-content {
    background: $white;
  }  
  .hamburger-menu-icon {
    display: block;
    font-size: 1.3em;
    margin: 0.25em;
    padding: 0.25em;
    width: 1em;
    height: 1em;
    background: transparent url('menu-icon.png') no-repeat center;
    background-size: cover;
    &:hover {
      background-image: url('menu-icon-hover.png');
    }
  }
}
.hero-wrapper {
  position: relative;
  background: $primary-color url('hero.jpg') no-repeat center right;
  background-size: cover;
  min-height: 15rem;
  @include breakpoint(medium up) {
    min-height: 20rem;
  }
  @include breakpoint(large up) {
    min-height: 30rem;
  }
  .hero-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .overlay-container {
      width: 100%;
      height: 100%;
      display: table;
      .overlay-inner-container {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        h1 {
          text-transform: uppercase;
          color: $white;
        }
        .search-box {
          input[type=search] {
            height: 3em;
            background-size: 2em;
            &:focus {
              background: $white;
            }
          }
        }
      }
    }
  }
}
.footer {
  background: $black;
  text-align: center;
  color: darken($white, 30);
  .social {
    background: $dark-gray;
    padding: 0.5rem 0;
    font-family: $body-font-family-bold;
    .social-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      text-transform: uppercase;
      li {
        display: inline-block;
        a {
          display: block;
          padding: 0.5rem;
          img {
            height: 2rem;
          }
        }
      }
    }
  }
  .colofon {
    padding: 0.5rem 0;
    font-size: 0.8rem;
  }
}
.section-header {
  text-transform: uppercase;
}
.grid-section {
  background: $bright-gray;
  .category-icon {
    padding: 1rem;
    display: block;
    background: $white;
    @include transition(0.4s, background);
    @include box-shadow(2px, 2px, 0, $light-gray);
    &:hover {
      background: $primary-color;
      h4 {
        color: $white;
      }
    }
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(map-get($grid-column-gutter, small));
    }
    @include breakpoint(medium up) {
      margin-bottom: rem-calc(map-get($grid-column-gutter, medium));
    }
    .category-icon-image {
      padding: 0 4rem;
    }
    h4 {
      text-transform: uppercase;
      color: $black;
      @include transition(0.4s, color);
    }
  }
}
.contact-us-button {
  background: $black;
  text-transform: uppercase;
  font-family: $header-font-family;
  @include transition(0.2s, background); 
  &:hover {
    background: $primary-color;
  }
}
.fancy-list {
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    color: $body-font-color;
    padding: 0.5rem 0 0.5rem;
    border-bottom: 1px solid lighten($body-font-color, 20);
    display: block;
    @include transition(0.2s, color);
    &:hover {
      color: $primary-color;
    }
  }
  li {
    &:last-child {
      a {
        border-bottom: none;
      }
    }
  }
}
.text-section {
  background: $white;
}
.category-section {
  background: $bright-gray url('sidebar-bg.png') center;
}
.article-section {
  background: $white url('sidebar-bg-bright.png') center;
  .main-column {
    background: $white;
  }
  .sidebar {
    background: $bright-gray;
  }
}
.main-column {
  background: $bright-gray;
}
.sidebar {
  background: $light-gray;
  position: relative;
  .fancy-list {
    a {
      color: $primary-color;
      border-bottom-color: $medium-gray;
      &:hover {
        color: $body-font-color;
      }
    }
  }
}
.sub-nav-section {
  background: $white;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  .breadcrumbs {
    line-height: 2.75;
    margin-bottom: 0;
  }
}
.search-box {
  input[type=submit] {
    display: none;
  }
  input[type=search] {
    margin-bottom: 0;
    height: 1.8125rem;
    padding: 0.25rem 0.5rem;
    box-shadow: none;
    border: 1px solid $light-gray;
    background: $white url("search-icon.png") no-repeat right center;
    background-size: 1.2rem;
    &:focus {
      background: transparent;
    }
  }
}
.accordion-title {
  font-size: 1rem;
  font-family: $body-font-family-bold;
  &::before {
    margin-top: -0.75rem;
    font-size: 1.5em;
    color: $primary-color;
  }
}
.accordion-content {
  font-size: 0.9em;
}
.community-section {
  .nav-spaced,
  .nav-bordered {
    ul {
      @include menu-base;
      li {
        margin-bottom: 0;
        margin-right: 0.24rem;
        display: inline-block;
        a {
          border-bottom: none;
          @include button(false, $primary-color, $black, $white);
        }
      }       
    }
  }
  .pagination {
    margin-bottom: 0;
    ul {
      li {
        margin-bottom: 0;
        a {
          border-bottom: none;
        }
      }
    }
  }
  input[type=submit] {
    @include button(false, $primary-color, $black, $white);
    font-family: $body-font-family-bold;
  }
  .recent-activity-header {
    text-transform: uppercase;
  }
  .recent-activity-controls {
    a {
      @include button(false, $primary-color, $black, $white);
      font-family: $body-font-family-bold;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    .recent-activity-item-parent {
      border-bottom: none;
      font-family: $body-font-family-bold;
      padding-bottom: 0;
    }
    .recent-activity-item-meta {
      font-size: 0.8rem;
      color: lighten($dark-gray, 20);
      margin-bottom: 1rem;
      div {
        display: inline-block;
        margin-right: 1rem;
      }
      .recent-activity-item-comment {
        padding-left: 2rem;
        background: url('comments-icon.png') no-repeat center;
        background-size: 0.8rem;
      }
    }
    a {
      color: $primary-color;
      padding: 0.5rem 0 0.5rem;
      border-bottom: 1px solid lighten($body-font-color, 20);
      display: block;
      @include transition(0.2s, color);
      &:hover {
        color: $body-font-color;
      }
    }
    li {
      margin-bottom: 1rem;
    }
  }

}